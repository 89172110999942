import React, { useState } from 'react';

function FAQ() {
    const [activeIndex, setActiveIndex] = useState(null);

    // Function to toggle the collapse state of a question
    const toggleCollapse = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    // Function to determine the arrow direction based on collapse state


    return (
        <div className="row">
            {/* FAQ questions and answers section */}
            <div className="col-lg-6">
                <div className="accordion faq-wrapper" id="accordionExample">
                    {/* FAQ question 1 */}
                    <div className="single-faq">
                        <button
                            className="w-100"
                            type="button"
                            onClick={() => toggleCollapse(1)}
                            aria-expanded={activeIndex === 1}
                            aria-controls="collapseOne"
                        >
                            How do I create an invoice?
                            {/* {activeIndex === 1 ? '▲' : '▼'} */}
                        </button>
                        <div
                            id="collapseOne"
                            className={`collapse ${activeIndex === 1 ? 'show' : ''}`}
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                        >
                            <div className="faq-content">
                                To create an invoice, log in to your OKBILL account and navigate to the "Invoices" section. Click on the "Create Invoice" button and fill in the required details such as client information, invoice items, and due date. Once you've entered all the necessary information, click "Save" to generate the invoice.
                            </div>
                        </div>
                    </div>
                    <div className="single-faq">
                        <button
                            className="w-100"
                            type="button"
                            onClick={() => toggleCollapse(2)}
                            aria-expanded={activeIndex === 2}
                            aria-controls="collapseTwo"
                        >
                            Can I customize my invoices?
                        </button>
                        <div
                            id="collapseTwo"
                            className={`collapse ${activeIndex === 2 ? 'show' : ''}`}
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                        >
                            <div className="faq-content">
                                Yes, you can customize your invoices to reflect your brand identity. In the invoice creation process, you'll have the option to add your company logo, choose a color scheme, and include personalized messages or terms. Additionally, you can save invoice templates for future use.
                            </div>
                        </div>
                    </div>
                    <div className="single-faq">
                        <button
                            className="w-100"
                            type="button"
                            onClick={() => toggleCollapse(3)}
                            aria-expanded={activeIndex === 3}
                            aria-controls="collapseThree"
                        >
                            How do I send an invoice to my client?
                        </button>
                        <div
                            id="collapseThree"
                            className={`collapse ${activeIndex === 3 ? 'show' : ''}`}
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                        >
                            <div className="faq-content">
                                After creating your invoice, you can easily send it to your client directly from the OKBILL platform. Simply select the invoice you want to send, choose the delivery method (email or download), and enter your client's email address if sending via email. Click "Send" to deliver the invoice to your client's inbox.
                            </div>
                        </div>
                    </div>
                    {/* Add similar structure for other FAQs */}
                </div>
            </div>

            {/* FAQ image section */}
            <div className="col-lg-6">
                <div className="faq-image">
                    <img src="assets/img/faq/faq-img.svg" alt="FAQ Image" />
                </div>
            </div>
        </div>
    );
}

export default FAQ;
