// Header.js
import React from "react";
import { Link } from "react-router-dom";
import Logo from "./Assets/Images/logo-remove_bg_rm.png";

function Header() {
    return (
        <header className="header">
            <div className="navbar-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg">
                                <a className="navbar-brand" href="index.html">
                                    <img src={Logo} alt="Logo" />
                                </a>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="toggler-icon" />
                                    <span className="toggler-icon" />
                                    <span className="toggler-icon" />
                                </button>
                                <div
                                    className="collapse navbar-collapse sub-menu-bar"
                                    id="navbarSupportedContent"
                                >
                                    <ul id="nav" className="navbar-nav ms-auto">
                                        <li className="nav-item">
                                            <a className="page-scroll active" href="#home">
                                                Home
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="page-scroll" href="#feature">
                                                Features
                                            </a>
                                        </li>
                                        {/* <li className="nav-item">
                                            <Link to="/about">About</Link>
                                        </li> */}
                                        <li className="nav-item">
                                            <a className="page-scroll" href="#About">
                                                About
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="page-scroll" href="#faq">
                                                FAQ
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
