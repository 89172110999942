import React from "react";
import { Link } from "react-router-dom";
import "./About.css"; // Import your CSS file for About page styling
import Logo from "./Assets/Images/logo.png"; // Import the logo image
import FeatureIcon from "./Assets/Images/app/banner.png"; // Example feature icon
import Download from "./Assets/Images/app/download.jpeg"; // Example feature icon
import Header from "./Header"; // Import the common header component

function About() {
  return (
    <div className="about-content">
      {/* <img src={Download} alt="OKBILL Logo" className="logo" /> */}
      <h1 className="mb-25 wow fadeInUp">About OKBILL</h1>
      <p className="mb-2 wow fadeInUp">
        Welcome to OKBILL – your ultimate solution for effortless invoice and
        bill creation! Our mission is to provide you with a hassle-free
        invoicing experience through our user-friendly app.
      </p>
      <p className="mb-2 wow fadeInUp">
        With OKBILL, you can generate invoices quickly and share them with ease.
        Our app is designed to save you time and streamline your invoicing
        process.
      </p>
      <p className="mb-25 wow fadeInUp">
        We are committed to continuously improving OKBILL to meet your invoicing
        needs. Stay tuned for updates and new features!
      </p>

      <h2 className="mb-25 wow fadeInUp pt-80">Key Features</h2>
      <ul>
        {/* <li>
                Effortlessly create and customize invoices and bills */}
        <img
          src={FeatureIcon}
          alt="DOWNLOAD OKBILL - Invoice,billing app"
          className="feature-icon"
        />
        {/* </li> */}
        {/* Add similar styled list items for other features */}
      </ul>
      <div className="row justify-content-center">
        <div className="col-xxl-8 col-xl-6 col-lg-7 text-center">
          <h2 className="mb-25 wow fadeInUp pt-80">Why Choose OKBILL?</h2>
          <p className="wow fadeInUp">
            OKBILL offers a simple and intuitive platform for all your invoicing
            needs. Whether you're a freelancer, small business owner, or
            entrepreneur, OKBILL empowers you to create professional invoices in
            minutes, allowing you to focus on what matters most – growing your
            business.
          </p>
        </div>
      </div>

      {/* <Link to="/" className="main-btn btn-hover">
                        Go Back
                    </Link> */}
    </div>
  );
}

export default About;
