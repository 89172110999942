// src/DeleteAccount.js
import React from "react";

import "./DeleteAccount.css";
import Logo from "./Logo";

const DeleteAccount = () => {
  return (
    <div className="delete-account-container">
      <Logo />
      <div className="delete-account-content">
        <h2>How can I delete my OkBill account?</h2>
        <div className="delete-account-section">
          <h4>
            If you wish to delete your OkBill account, follow this process:
          </h4>
          <ol>
            <li>Compose an email to support@okbill.in .</li>
            <li>Use 'Delete Account' as the subject line.</li>
            <li>Include your OkBill email address in the email.</li>
            <li>Provide a brief reason for canceling your account.</li>
            <li>Send the email to initiate the account deletion process.</li>
          </ol>
        </div>
        <div className="delete-account-section">
          <h4>
            If you wish to delete your OkBill account through the app, follow
            this process:
          </h4>
          <ol>
            <li>Open the OkBill app and go to the Profile screen.</li>
            <li>Select the “Delete account” option on the screen.</li>
            <li>Tap the Delete account & Confirm the Pop up.</li>
            <li>
              By confirming the pop up, the account deletion process is
              completed.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
