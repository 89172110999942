// src/Logo.js
import React from "react";
import logoImage from "../Assets/Images/logo.png"; // Replace with your logo image file path

const Logo = () => {
  return (
    <div className="logo-container">
      <img src={logoImage} alt="okbill Logo" />
    </div>
  );
};

export default Logo;
