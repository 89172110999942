import React, { useEffect } from "react";

const DeeplinkRedirect = () => {
  useEffect(() => {
    // Replace 'okbillapp://NewInvoice' with your app's custom URL scheme
    window.location.href = "okbillapp://NewInvoice";
  }, []);

  return (
    <div>
      <h1>Redirecting...</h1>
      <p>
        If you are not redirected automatically,{" "}
        <a href="okbillapp://NewInvoice">click here</a>.
      </p>
    </div>
  );
};

export default DeeplinkRedirect;
