import React, { useState, useEffect } from "react";
import "./App.css";
import Loader from "./Loader";
import { BrowserRouter as Router, Link } from "react-router-dom";
import IntroPage from "./Assets/Images/app/okbill-intro.png";
import Header from "./Header";
import About from "./About";
import FAQ from "./FAQ";

function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <Loader />
      ) : (
        <div>
          <meta charSet="utf-8" />
          <meta httpEquiv="x-ua-compatible" content="ie=edge" />
          <title>
            OKBILL | Fastest Growing Billing & Invoicing App by a Young Team
          </title>
          <meta
            name="description"
            content="OKBILL is a billing and invoice app designed for small businesses. Easily manage your GST-compliant invoices and streamline your billing process."
          />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta
            name="keywords"
            content="billing app, invoicing software, GST-compliant invoices, small business billing, OKBILL"
          />
          <link
            rel="shortcut icon"
            type="image/x-icon"
            href="assets/img/favicon.svg"
          />
          <link
            rel="stylesheet"
            href="assets/css/bootstrap-5.0.0-beta2.min.css"
          />
          <link rel="stylesheet" href="assets/css/LineIcons.2.0.css" />
          <link rel="stylesheet" href="assets/css/tiny-slider.css" />
          <link rel="stylesheet" href="assets/css/animate.css" />
          <link rel="stylesheet" href="assets/css/main.css" />

          <script type="application/ld+json">
            {`{
              "@context": "http://schema.org",
              "@type": "SoftwareApplication",
              "name": "OKBILL",
              "description": "The fastest growing invoicing software developed by a young and dynamic team. Create and manage invoices effortlessly.",
              "applicationCategory": "BusinessApplication",
              "offers": {
                "@type": "Offer",
                "price": "0.00",
                "priceCurrency": "INR"
              }
            }`}
          </script>

          <Header />

          <section id="home" className="hero-section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="hero-content">
                    <h1 className="wow fadeInUp" data-wow-delay=".2s">
                      OKBILL - Hassle-Free Invoice and Bill Creation
                    </h1>
                    <p className="wow fadeInUp" data-wow-delay=".4s">
                      Introducing OKBILL – your go-to app for hassle-free
                      invoice and bill creation. With its easy-to-use interface
                      and smart system, making invoices is a breeze. Just add
                      your products and generate invoices in a snap by entering
                      their codes. Share them effortlessly with your clients and
                      partners.
                    </p>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.okbillapp.ssn"
                      className="main-btn btn-hover wow fadeInUp"
                      data-wow-delay=".6s"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hero-img wow fadeInUp" data-wow-delay=".5s">
                    <img
                      src={IntroPage}
                      alt="OKBILL Invoicing,billing,gst app"
                      className="img-1"
                      style={{
                        height: "75vh",
                        borderRadius: "8px",
                        right: "100px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="feature" className="feature-section pt-140">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xxl-10 col-xl-6 col-lg-7">
                  <div className="section-title text-center mb-30">
                    <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                      Awesome Features
                    </h2>
                    <p className="wow fadeInUp" data-wow-delay=".4s">
                      Create professional invoices in seconds with our intuitive
                      platform. Simply input your billing details, and our
                      streamlined process takes care of the rest. Say goodbye to
                      manual invoicing hassles and hello to efficiency.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-8 col-sm-10">
                  <div className="single-feature">
                    <div className="icon color-1">
                      <i className="lni lni-pointer-up" />
                    </div>
                    <div className="content">
                      <h3>Easy To Use</h3>
                      <p>
                        Our invoice app offers a seamless user experience,
                        allowing you to effortlessly create professional
                        invoices. With a simple interface, input client details,
                        add services/products, and send invoices with ease. Save
                        time and focus on business growth with our intuitive
                        invoicing solution.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-8 col-sm-10">
                  <div className="single-feature">
                    <div className="icon color-2">
                      <i className="lni lni-book" />
                    </div>
                    <div className="content">
                      <h3>Instant Invoice Generation</h3>
                      <p>
                        Effortlessly generate invoices with just a few clicks,
                        saving you valuable time and effort. Our instant invoice
                        generation feature ensures that you can create
                        professional invoices on the fly, streamlining your
                        invoicing process and allowing you to focus on growing
                        your business.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-8 col-sm-10">
                  <div className="single-feature">
                    <div className="icon color-3">
                      <i className="lni lni-alarm" />
                    </div>
                    <div className="content">
                      <h3>Automated Invoice Reminders</h3>
                      <p>
                        Streamline your payment collection process with
                        automated reminders. Set up personalized notifications
                        to gently nudge clients about overdue invoices,
                        improving cash flow effortlessly. Save time and ensure
                        prompt payments with this convenient feature.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="About" className="About-section pt-120">
            <About />
          </section>

          <section id="faq" className="faq-section pt-120">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xxl-12 col-xl-6 col-lg-7">
                  <div className="section-title text-center mb-60">
                    <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                      Frequently Asked Queries
                    </h2>
                    <p className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                      Browse through our FAQ section for quick and comprehensive
                      answers to common questions. Whether you're curious about
                      our services, billing, or functionality, we've got you
                      covered. Streamline your experience with our informative
                      FAQ, designed to provide clarity and assistance whenever
                      you need it.
                    </p>
                  </div>
                </div>
              </div>
              <FAQ />
            </div>
          </section>

          <footer id="footer" className="footer">
            <div className="container">
              <div className="widget-wrapper">
                <div className="row">
                  <div className="col-xl-2 col-md-6">
                    <div className="footer-widget">
                      <h3>About Us</h3>
                      <ul className="links">
                        <li>
                          <a href="#home">Home</a>
                        </li>
                        <li>
                          <a href="#feature">Features</a>
                        </li>
                        <li>
                          <a href="#About">About</a>
                        </li>
                        <li>
                          <a href="#faq">FAQ</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-2 col-md-6">
                    <div className="footer-widget">
                      <h3>Contact Us</h3>
                      <ul className="links">
                        <li>
                          <a href="mailto:support@okbill.com">
                            support@okbill.com
                          </a>
                        </li>
                        {/* <li>
                          <a href="tel:+1234567890">+123 456 7890</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-2 col-md-6">
                    <div className="footer-widget">
                      <h3>Follow Us</h3>
                      <ul className="social">
                        <li>
                          <a href="https://facebook.com">Facebook</a>
                        </li>
                        <li>
                          <a href="https://twitter.com">Twitter</a>
                        </li>
                        <li>
                          <a href="https://instagram.com">Instagram</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      )}
    </div>
  );
}

export default Home;
