// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import DeleteAccount from "./pages/DeleteAccount";
import About from "./About";
import TermsOfService from "./TermsOfService";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import DeeplinkRedirect from "./pages/DeeplinkRedirect";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/remove-account" element={<DeleteAccount />} />
          <Route path="/deeplink" element={<DeeplinkRedirect />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
