import React from 'react';
import './terms-of-service.css'; // Import external CSS file

function TermsOfService() {
    return (
        <div className="terms-of-service-container">
            <h1 className="terms-of-service-title">Terms of Service</h1>
            <div className="terms-of-service-content">
                <p>
                    By using the OKBILL website and services, you agree to comply with and be bound by the following terms and conditions of use. Please review these terms carefully. If you do not agree to these terms, you should not use this website or our services.
                </p>
                <p>
                    <strong>Use of Our Services:</strong> You must follow any policies made available to you within the OKBILL services.
                </p>
                <p>
                    <strong>Content:</strong> Our services allow you to submit, store, and access content. You retain ownership of any intellectual property rights that you hold in that content.
                </p>
                <p>
                    <strong>Modifications:</strong> We reserve the right to modify or discontinue, temporarily or permanently, our services with or without notice.
                </p>
                <p>
                    <strong>Termination:</strong> We may terminate or suspend access to our services immediately, without prior notice or liability, for any reason whatsoever.
                </p>
                <p>
                    <strong>Disclaimer:</strong> Your use of our services is at your sole risk. The services are provided on an "as is" and "as available" basis.
                </p>
                <p>
                    Please review our <a href="/privacy-policy">Privacy Policy</a> for information on how we collect, use, and safeguard your personal information.
                </p>
            </div>
        </div>
    );
}

export default TermsOfService;
